import React, { useState, useEffect, useCallback } from 'react';
import { Container } from './styles';

import api from '~/services/api';
import InputSelect from '../Inputs/InputSelect';

interface Options {
  label: string | undefined;
  value: number | string | undefined;
}

interface IConta {
  cod_cc: number;
  des_cc: string;
  cod_banco: number;
  descricao: string;
}

interface ContaProps {
  value: Options | undefined | null;
  loja: number[] | undefined | number;
  onChange(val: any, data: boolean): void;
  isRequired?: boolean;
  isDisabled?: boolean;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  menuPosition?: any;
  menuPlacement?: any;
  minMenuHeight?: number;
  label?: string | undefined;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */
const ContaBanco: React.FC<ContaProps> = (props) => {
  const {
    loja,
    onChange,
    value,
    isRequired,
    isDisabled,
    setInvalid,
    iniInicializado,
    menuPosition,
    menuPlacement,
    minMenuHeight,
    label = 'Conta',
  } = props;
  // const user = useAuth();
  // const { options } = props;
  // armazena se o input passou na validação

  // armazena se a validação foi iniciada
  const [contas, setContas] = useState<Options[]>([]);
  const [contasAtivas, setContasAtivas] = useState<Options[]>([]);
  // const [formData, setFormData] = useState<IFormData>({
  //   text: '',
  //   option: value,
  // });
  useEffect(() => {
    const getContasLojas = async () => {
      const { data } = await api.get(`/contas/loja/${loja}`);
      if (data.success && data.data) {
        const options = data.data.map((conta: IConta) => ({
          label: conta.descricao,
          value: conta.cod_cc,
        }));

        setContas(options);

        const contaAtiva = data.data
          .filter((f: any) => f.flg_inativa !== true && f.descricao !== null)
          .map((conta: IConta) => ({
            label: conta.descricao,
            value: conta.cod_cc,
          }));

        setContasAtivas(contaAtiva);
      }
    };
    if (loja) {
      getContasLojas();
    }
  }, [loja]);

  const handleChangeConta = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      onChange(val, isInvalid);
    },
    [onChange],
  );

  return (
    <Container>
      <div className="col-sm-12">
        <InputSelect
          placeholder={!loja ? 'Informe a Loja Primeiro' : 'Selecione'}
          options={contasAtivas}
          label={label}
          noOptionsMessage="Nenhum registro encontrado"
          value={
            contas.find((conta: Options) => {
              return conta.value === value?.value;
            }) ?? null
          }
          isRequired={isRequired}
          setInvalid={setInvalid}
          isDisabled={isDisabled}
          menuPosition={menuPosition}
          menuPlacement={menuPlacement}
          minMenuHeight={minMenuHeight}
          iniInicializado={iniInicializado}
          onChange={(newValue: any, isInvalid = true) => {
            handleChangeConta(newValue, isInvalid);
          }}
        />
      </div>
    </Container>
  );
};

export default ContaBanco;
