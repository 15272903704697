import { Modal } from 'react-bootstrap';
import Button from '@atlaskit/button';
import { CircularProgress } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowParams } from '@material-ui/data-grid';
import api from '~/services/api';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import { RiCloseLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { ConfirmButton } from '../../../Buttons';
import {
  BtnGroupContainer,
  ContainerGrid,
  FilterConta,
  GrupButtons,
  InputSearch,
} from '../../styles';

interface FormProps {
  showModal: boolean;
  value: string | number | undefined;
  onChange(data: any, data1: boolean, codConta: number): any;
  setShowModal: (val: any) => void;
  setConta: (val: any) => void;
}

type Contas = {
  id: number;
  conta_contabil: string;
  conta_reduzida?: string;
  des_conta_contabil: string;
};
/**
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 * @value Valor correspondente ao cod_conta
 */
export const ModalContabil: React.FC<FormProps> = (props) => {
  const { setShowModal, showModal, value, onChange, setConta } = props;
  const [tipoFiltro, setTipoFiltro] = useState<number>(0);
  const [filtro, setFiltro] = useState<string>('');
  const [selectedConta, setSelectedConta] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const [contas, setContas] = useState<Array<Contas> | []>([]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      field: 'conta_contabil',
      headerName: 'Conta Contábil',
      width: 190,
      editable: false,
    },
    {
      field: 'conta_reduzida',
      headerName: 'Reduzida',
      width: 190,
      editable: false,
    },
    {
      field: 'des_conta_contabil',
      headerName: 'Descrição',
      width: 500,
      editable: false,
      headerClassName: 'hideRightSeparator',
    },
  ];

  useEffect(() => {
    if (showModal) {
      setLoading(true);
      handleContasFiltros();
      setLoading(false);
    } else {
      setFiltro('');
      setTipoFiltro(0);
    }
  }, [showModal]);

  useEffect(() => {
    handleContasFiltros();
  }, [filtro, tipoFiltro]);

  const handleContasFiltros = useCallback(async () => {
    if (String(filtro).length >= 1) {
      setLoading(true);
    }
    try {
      const escapedFilter = filtro.includes("'")
        ? filtro.replace(/'/g, "''")
        : filtro;

      const { data } = await api.get(
        `contaContabil/conta/${tipoFiltro}?campo=${escapedFilter}`,
      );

      if (data.success) {
        const contasFormatada: any = [];
        // eslint-disable-next-line array-callback-return
        data.data.map((element: any) => {
          contasFormatada.push({
            id: element.cod_conta,
            conta_contabil: element.conta_contabil,
            conta_reduzida: element.conta_reduzida,
            des_conta_contabil: element.des_conta_contabil,
          });
        });
        setContas(contasFormatada);
      }
    } catch (error: any) {
      setContas([]);
      setLoading(false);
    }
    setLoading(false);
  }, [filtro, tipoFiltro]);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    if (row === null) {
      return toast.warning(
        'Clique sobre a Conta Contábil que deseja selecionar!',
      );
    }
    setSelectedConta(row);
  };

  const handleConfirm = useCallback(() => {
    if (selectedConta.id) {
      setConta({
        cod_conta: selectedConta.id,
        conta_contabil: selectedConta.conta_contabil,
        conta_reduzida: selectedConta.conta_reduzida,
        des_conta_contabil: selectedConta.des_conta_contabil,
      });
      onChange(selectedConta.conta_reduzida, false, selectedConta.id);
      setContas([]);
      setShowModal(false);
      toast.success(
        `Conta Contábil ${selectedConta.conta_reduzida} foi adicionada`,
      );
    } else {
      toast.warning('Clique sobre a Conta Contábil que deseja selecionar!');
    }
  }, [
    onChange,
    selectedConta.conta_contabil,
    selectedConta.conta_reduzida,
    selectedConta.des_conta_contabil,
    selectedConta.id,
    setContas,
    setConta,
    setShowModal,
  ]);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      centered
      size="xl"
    >
      <Modal.Header style={{ paddingBottom: '30px' }}>
        <Modal.Title
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            color: '#57069e',
          }}
        >
          Selecione a Conta Contábil
          <Button
            onClick={() => {
              setShowModal(false);
              setContas([]);
            }}
          >
            <RiCloseLine style={{ color: '#57069e' }} />
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FilterConta>
          <GrupButtons>
            <div>
              <BtnGroupContainer>
                Filtrar por:
                <Button
                  key="ContaContabil"
                  className={
                    tipoFiltro === 0
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  value="0"
                  style={{
                    marginLeft: '10px',
                    borderRadius: '4px 0px 0px 4px',
                  }}
                  type="button"
                  onClick={() => {
                    setTipoFiltro(0);
                  }}
                >
                  Conta Contábil
                </Button>
                <Button
                  key="reduzida"
                  className={
                    tipoFiltro === 1
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  value="0"
                  style={{
                    color: '#fff',
                  }}
                  type="button"
                  onClick={() => {
                    setTipoFiltro(1);
                  }}
                >
                  Reduzida
                </Button>
                <Button
                  key="desc"
                  className={
                    tipoFiltro === 2
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  value="0"
                  style={{ borderRadius: '0px 4px 4px 0px' }}
                  type="button"
                  onClick={() => {
                    setTipoFiltro(2);
                  }}
                >
                  Descrição
                </Button>
              </BtnGroupContainer>
            </div>

            <InputSearch
              type="text"
              placeholder="Pesquisar"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFiltro(e.target.value);
              }}
            />

            {loading ? (
              <CircularProgress
                size={15}
                style={{
                  color: '#57069e',
                  marginLeft: '-25px',
                  marginTop: '10px',
                  zIndex: '1',
                }}
              />
            ) : (
              <BiSearch
                style={{
                  color: '#57069e',
                  marginLeft: '-25px',
                  marginTop: '10px',
                  zIndex: '1',
                }}
              />
            )}
          </GrupButtons>
        </FilterConta>

        <ContainerGrid>
          <DataGrid
            className="DataGrid"
            style={{ height: '325px' }}
            rows={contas}
            columns={columns}
            pageSize={4}
            sortModel={[{ field: 'des_conta_contabil', sort: 'asc' }]}
            columnBuffer={10}
            onRowClick={onRowClick}
            autoHeight
            disableColumnMenu
            localeText={{
              noRowsLabel: 'Nenhum registro encontrado...',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar colunas',
            }}
          />
        </ContainerGrid>

        <div className="d-flex justify-content-end">
          <ConfirmButton
            style={{
              marginTop: '30px',
            }}
            onClick={() => {
              handleConfirm();
            }}
          >
            <BsCheck style={{ marginRight: '3px', fontSize: '20px' }} />{' '}
            Selecionar
          </ConfirmButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
